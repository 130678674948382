import Constants from '../../config.local.js'

var CardsMixin = {
    mixins: [],
	methods: {
        getCards: async function() {
            const url = this.constructRoute(Constants.GET_CARDS_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("CardsMixin::getCards", url)
            .catch(error => {
                console.error("CardsMixin::getCards => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },
        saveCard: async function(cards_id, cards_done, cards_skip) {
            const params = {
                cards_done: cards_done,
                cards_skip: cards_skip
            }

            const url = this.constructRoute(Constants.SAVE_CARDS_URL, {cards_id: cards_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("CardsMixin::getCards", url, params, false)
            .catch(error => {
                console.error("CardsMixin::getCards => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },
        checkCards: async function() {
            const url = this.constructRoute(Constants.CHECK_CARDS_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("CardsMixin::checkCard", url, {}, false)
            .catch(error => {
                console.error("CardsMixin::checkCard => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        }
	}
}

export default CardsMixin
