<template>
    <div>
        <LoadingSpinner v-show="loading" class="col-12"/>
    	<div v-show="!loading" @submit.stop.prevent="addLieu" class="row">
    		<div class="col-12">

                <div class="row">
                    <div class="col">
                        <p>{{ $t('lieu.lieu_detention_descriptif') }}</p>
                    </div>
                    <div v-if="online && lieu_type && lieu_type.lieutype_code == 'RESI'" class="col-auto ml-auto d-flex">
                        <a href="https://groomy-web.s3.eu-west-3.amazonaws.com/release/img/20230828_PDF_Interactif_Lieu-Dentention.pdf" download target="_blank" class="btn btn-secondary">
                            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('lieu.modele') }}
                        </a>
                    </div>
                </div>

    			<form id="formAjoutLieu">
    				<div class="form-group">
    					<label>{{ $t('lieu.label_lieu') }}<sup>*</sup></label>
    					<input class="form-control" type="text" :placeholder="$t('lieu.label')" v-model="lieu_label" id="lieu_label" required>
    				</div>
    				<div class="form-group">
    					<label>{{ $t('lieu.adresse') }}</label>
    					<input class="form-control" type="text" :placeholder="$t('lieu.adresse')" v-model="lieu_adresse" required>
    				</div>
    				<div class="form-group">
    					<label>{{ $t('lieu.cp') }}</label>
    					<input class="form-control" type="text" :placeholder="$t('lieu.cp')" v-model="lieu_cp" required>
    				</div>
    				<div class="form-group">
    					<label>{{ $t('lieu.ville') }}</label>
    					<input class="form-control" type="text" :placeholder="$t('lieu.ville')" v-model="lieu_ville" required>
    				</div>

    				<div class="form-group">
    					<label>{{ $t('lieu.type') }}</label>

						<e-select
							track-by="lieutype_id"
							label="lieutype_label"
							:options="types_lieu"
							:allow-empty="false"
							:show-labels="false"
							v-model="lieu_type"
						/>
    				</div>

                    <div v-if="lieu_type && lieu_type.lieutype_code == 'RESI'" class="form-group">
                        <label>{{ $t('lieu.delegue_bien_etre') }}</label>
                        <SearchContact
                            :contact_selected.sync="contact"
                            :preselected="contact"
                            :operators="true"
                        />
                    </div>

                    <div v-if="online && lieu_type && lieu_type.lieutype_code == 'RESI'" class="form-group">
                        <label>{{ $t('lieu.caracteristiques_lieu') }} :</label>
                        <b-form-file
                            v-model="document_file"
                            :accept="type_file_accepted"
                            :placeholder="$t('invoice.devis.choisir_document')"
                            :drop-placeholder="$t('fichier.drop_files')+'...'"
                        ></b-form-file>
                    </div>

                    <div v-show="document_file" class="mt-3">
                        {{ $t('fichier.selected_files') }} :
                        <span> {{ document_file ? document_file.name : '' }}</span>
                    </div>

                    <div v-if="online && lieu_type && lieu_type.lieutype_code == 'RESI'" class="form-group">
                        <label>{{ $t('lieu.image_localisation') }} :</label>
                        <b-form-file
                            v-model="image_file"
                            :accept="type_file_accepted_img"
                            :placeholder="loc_placeholder"
                            :drop-placeholder="$t('fichier.drop_files')+'...'"
                        ></b-form-file>
                    </div>

                    <!-- Message d'erreur si besoin -->
                    <ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

                    <div class="text-center mt-3">
                        <button @click="cancel" class="btn btn-secondary rounded-pill mr-4">
                           {{ $t('action.annuler') }} <font-awesome-icon :icon="['fal', 'times']" /> 
                        </button>
        				<button :title="btnTitle" class="btn btn-primary rounded-pill" :class="{ disabled: working }" v-on:click="sendFormCheck">
                            {{ btnTitle }}
                            <font-awesome-icon v-if="!working" class="ml-2" :icon="['fal', 'plus-circle']" />
                            <font-awesome-icon v-else class="ml-2" :icon="['far', 'spinner']" spin />
                        </button>
                    </div>

    			</form>
    		</div>
    	</div>

    </div>
</template>

<script type="text/javascript">
    import Lieux from "@/mixins/Lieux.js"
    import Horse from "@/mixins/Horse.js"
    import Contact from "@/mixins/Contact.js"
    import ErrorAlert from "GroomyRoot/components/Alert/ErrorAlert"
    import LoadingSpinner from 'GroomyRoot/components/Logos/LoadingSpinner_35'
    import Shutter from "@/mixins/Shutter.js"
    import ShutterCards from "@/mixins/Cards.js"

	export default {
		name: "AjoutLieu",
        mixins: [Lieux, Horse, Contact, Shutter, ShutterCards],
        components: {
            ErrorAlert,
            LoadingSpinner,
            SearchContact : () => import('@/components/Contract/SearchContact')
        },
        props:[
            'lieu_id',
            'type_code',
            'code_onboarding',
            'type'
        ],
		data () {
			return {
                edit_lieu: false, // passe à true si on a un lieu.lieu_id
                horse_id: null,
                horse_residence: {},
                types_lieu: [],
                lieu_label: '',
                lieu_adresse: '',
                lieu_cp: '',
                lieu_ville: '',
                lieu_type: {},
                residence_principale: false,
                message_erreur_code: '',
                working: false,
                loading: true,
                required_values: [
                    'lieu_label'
                ],
                contact: null,
                document_file: null,
                image_file: null,
                type_file_accepted: "image/jpeg, image/jpg, image/png, application/pdf",
                type_file_accepted_img: "image/jpeg, image/jpg, image/png",
                lieu_type_base: {},
                loc_placeholder: ''
			}
		},
		mounted() {
            this.init_component()
		},
		methods: {
            async init_component() {
                this.loc_placeholder = this.$t('invoice.devis.choisir_document')
				this.types_lieu = await this.getTypeLieu()
                this.types_lieu = this.types_lieu.filter(type => type.lieutype_visible == 1)
                if (this.$route.params.type === "sortie" || this.type === "sortie") {
                    this.types_lieu = this.types_lieu.filter(type => type.lieutype_code != 'RESI')
                }
                this.types_lieu.unshift( {lieutype_id:0, lieutype_label: this.getTrad("lieu.add_type_resid"), lieutype_key_trad:"AddType", lieutype_visible:1, lieutype_code:"NULL"} )

                if(this.lieu_id) {
                    this.edit_lieu = true

					const lieu = await this.getLieuById(this.lieu_id)

                    if(lieu) {
                        const type = this.types_lieu.find(type => type.lieutype_id == lieu.lieu_type)

                        this.lieu_label = lieu.lieu_label
                        this.lieu_adresse = lieu.lieu_adresse
                        this.lieu_cp = lieu.lieu_cp
                        this.lieu_ville = lieu.lieu_ville
                        this.lieu_type = type ?? this.types_lieu[0]
                        this.lieu_type_base = type ?? null
                        if(lieu.lieu_contact) {
                            this.contact = await this.getContactById(lieu.lieu_contact)
                        }

                        if (this.online) {
                            const file = await this.getDocumentByLieu(this.lieu_id)
                            if(file && file.files_id) {
                                this.document_file = new File([], file.files_original_name)
                            }
                            else if(lieu.lieu_img_url) {
                                //eslint-disable-next-line
                                this.loc_placeholder = lieu.lieu_img_url.match(/([^\/]+$)/)[0]
                            }
                        }
					}
				}
				else if(this.type_code) {
                    this.lieu_type = this.types_lieu.filter(type => type.lieutype_code == this.type_code)[0]
                }
                else {
					this.lieu_type = this.types_lieu[0]
				}

                this.loading = false
            },

            sendFormCheck() {
                if(this.checkForm(this.required_values)) {
                    this.message_erreur_code = "formulaire.erreur_champs_non_remplis"
                }
            },

            async addLieu() {
                if(this.working) return

                this.working = true
                if(this.lieu_type.lieutype_id == 0 && this.lieu_type.lieutype_code == "NULL"){
                    this.failureToast(this.getTrad("lieu.must_choose_type_resid"), true)
                    this.working = false
                    return
                }

                const params = {
                    'lieu_label': this.lieu_label,
                    'lieu_adresse': this.lieu_adresse,
                    'lieu_cp': this.lieu_cp,
                    'lieu_ville': this.lieu_ville,
                    'lieu_type': this.lieu_type.lieutype_id,
                    'lieu_contact': this.contact ? this.contact.contact_id : null
                }

                let lieu_id = null

                try {
                    // Si on a un id sur l'objet, c'est qu'on a un retour d'édition de lieu
                    if(this.edit_lieu) {
                        if(this.lieu_type_base && this.lieu_type_base.lieutype_code == 'RESI' && this.lieu_type.lieutype_code !== 'RESI') {
                            let check_residence = await this.getHorseByResidence(this.lieu_id)
                            if(check_residence.length > 0) {
                                this.working = false
                                this.failureToast("toast.residence_used")
                                return false
                            }
                        }

                        // On update le lieu
                        await this.updateLieu(this.lieu_id, params)
                            .catch(() => {
                                throw "An error occured while updating lieu."
                            })
                        lieu_id = this.lieu_id
                    }
                    // Sinon, le formulaire a été rempli pour une création de lieu
                    else {
                        // On insert
                        let lieu = await this.saveLieu(params)
                            .catch(() => {
                                throw "An error occured while saving lieu."
							})
                        lieu_id = lieu.lieu_id
                    }
                }
                catch(e) {
                    this.cancel()
                }

                if(this.document_file && this.document_file.size > 0 && this.online) {
                    if(lieu_id < 0) {
                        await this.$sync.force(true, true)
                        lieu_id = this.$sync.replaceWithReplicated('lieu', lieu_id)
                    }
                    await this.uploadDocumentLieu(lieu_id, this.document_file)
                }

                if(this.image_file && this.image_file.size > 0 && this.online) {
                    if(lieu_id < 0) {
                        await this.$sync.force(true, true)
                        lieu_id = this.$sync.replaceWithReplicated('lieu', lieu_id)
                    }
                    await this.uploadImageLieu(lieu_id, this.image_file)
                    await this.$sync.force(true, true)
                }

                if(this.code_onboarding) {
                    const params =  {
                        code: this.code_onboarding,
                        done: 1,
                        skip: 0
                    }

                    this.ok(params)
                    this.shutterPanel().close(this.code_onboarding)
                }
                else {
                    this.ok()
                    this.shutterPanel().close('lieu-add-form')
                    this.shutterPanel().close('lieu-edit-form')
                }
            }
		},
        computed: {
            btnTitle() {
                if(this.edit_lieu) return this.getTrad("global.modifier")
                return this.getTrad("global.ajouter")
            },
            online() {
                return window.navigator.onLine
            }
        }
	}
</script>
